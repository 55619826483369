import {
    db,
    GET,
} from '@/firebase.js'

import CompCard from '@/components/CompCard/CompCard.vue'
import Authblocker from '@/components/Authblocker/Authblocker.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Loading from '@/components/Loading/Loading.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'

export default {
    name: 'Companies',
    components: {
        CompCard,
        Authblocker,
        Tabs,
        Loading,
        Skeleton
    },
    data: function () {
        return {
            orgs: [],
            companiesFilter: 'Enrolled',
            companiesFilters: ['Enrolled', 'Blanks'],
            allCompaniesFetched: false,
            lastVisible: false,
            queryBusy: false
        }
    },
    methods: {
        resetQuery: function () {
            this.orgs = []
            this.lastVisible = null
            this.allCompaniesFetched = false
            this.queryBusy = false
        },
        setFilter: function (filter) {
            this.companiesFilter = filter
            this.resetQuery()
            this.getOrgs()
        },
        getOrgs: function () {
            if (this.allCompaniesFetched || this.queryBusy) return
            this.queryBusy = true
            let col = db.collection('orgs')
            col = col.limit(6)
            col = col.orderBy('name', 'asc')
            if (this.companiesFilter === 'Enrolled') {
                col = col.where('events.' + this.$event.id, '==', true)
            } else {
                col = col.where('blanked_events.' + this.$event.id, '==', true)
            }
            if (this.lastVisible) col = col.startAfter(this.lastVisible)
            GET(col, true).then(orgs => {
                this.lastVisible = orgs.docs[orgs.docs.length - 1]
                if (!this.lastVisible) {
                    console.log('All companies fetched')
                    return this.allCompaniesFetched = true
                }
                this.queryBusy = false
                orgs.forEach(org => {
                    this.orgs.push({
                        id: org.id,
                        name: org.data().name,
                        logo: org.data().logo,
                        oid: org.data().oid,
                        category: org.data().categories ? org.data().categories[0] : null
                    })
                })
                this.orgs.loaded = true
            })
        }
    },
    created: function () {
        if (this.orgs.length === 0) this.getOrgs()
    }
}